import React, { Component } from 'react';
import { Link } from 'gatsby';
import './styles/styles.scss';
import Image from '../../../../components/image/Image';
import { scrollToSection, getPathname } from '../../../../helpers/globals';
import { slide as Menu } from 'react-burger-menu';

class Navigation extends Component {
    componentDidMount() {
    }

    render() {
        const isSubPage = getPathname()?.includes('/contact') || getPathname()?.includes('/gdpr');
        const { white } = this.props;

        return (
            <>
             <nav className={white ? 'navigation navigation-white' : 'navigation'}>
                    <a href='/'>
                        <Image src={white ? 'logo-white.svg' : 'logo.svg'}
                            className='mx-auto shadow-xl navigation-logo'
                            alt='logo' />
                    </a>
                    {!isSubPage &&
                        <div className='navigation--links'>
                        <Link to='#activity' onClick={() => scrollToSection('#activity')}>About Us</Link>
                        <Link to='#pill' onClick={() => scrollToSection('#pill')}>DBD in a nutshell</Link>
                        <Link to='#operations' onClick={() => scrollToSection('#operations')}>Fields of Activity</Link>
                        <Link to='#cooperations' onClick={() => scrollToSection('#cooperations')}>Benefits</Link>
                        <Link to='#caseStudies' onClick={() => scrollToSection('#caseStudies')}>Case studies</Link>
                        <Link className='button' to='/contact'>Contact</Link>
                        <Link className='button-language' to='https://digitalbuildingdata.pl/'>
                            <Image src="world.svg" />
                            <span>
                                PL
                            </span>
                        </Link>
                    </div>
                    }
                </nav>
                <div className='hamburger'>
                    <Menu right width={'280px'}>
                        <Link to='#activity' onClick={() => scrollToSection('#activity')}>About Us</Link>
                        <Link to='#pill' onClick={() => scrollToSection('#pill')}>DBD in a nutshell</Link>
                        <Link to='#operations' onClick={() => scrollToSection('#operations')}>Fields of Activity</Link>
                        <Link to='#cooperations' onClick={() => scrollToSection('#cooperations')}>Benefits</Link>
                        <Link to='#caseStudies' onClick={() => scrollToSection('#caseStudies')}>Case Studies</Link>
                        <Link className='button' to='/contact'>Contact</Link>
                    </Menu>
                </div>   
            </>
        )
    }
}

export default Navigation;